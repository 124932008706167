import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div style={{ lineHeight: "35px" }}>
        <h2>COMPANY</h2>
        <p>Zeemust LLC</p>
        <p>Yerevan</p>
        <p className="address">
          Address: Room 300.1, Synergy Business Center, Armenak Armenakyan St
          2/5, Yerevan 0047
        </p>
        <p>Email: zeemust@pm.me</p>
      </div>
      {/* <div style={{lineHeight: '30px'}}>
        <h2>SERVICES</h2>
        <ul style={{}}>
          <li>Supporting teams to identify knowledge assets </li>
          <li> Improving access to knowledge assets </li>
          <li>Connecting people through knowledge items</li>
          <li>
            Designing “learning from experience” patterns, tools and
            technologies
          </li>
          <li>
            Testing and pivoting tools and technologies to address company needs
          </li>
          <li>Introducing tools and technologies into daily activities</li>
        </ul>
      </div> */}
    </div>
  );
};

export default Footer;
