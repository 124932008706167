import React from "react";
import graph from "../images/Group.png";
import pic from "../images/Frame.png";
import Greta from "../images/Greta.svg";
import Mariam from "../images/Mariam.svg";
import Varditer from "../images/Varditer.svg";

const Main2 = () => {
  return (
    <div className="mainservices">
      <div className="process">
        <div className="management">
          <h1 id="aboutus">About us</h1>
          <div className="lines">
            <div className="lineone"></div>
            <div className="linetwo"></div>
          </div>
          <span>
            Our mission lies in empowering companies through their wealth of
            knowledge, thereby ensuring sustained competitiveness and success.
          </span>
          <div className="cards">
            <div className="card">
              <img src={Mariam} alt="" />
              <p style={{ width: "63%", textAlign: "center" }}>
                Mariam Davtyan CEO of Zeemust Certified knowledge manager
              </p>
            </div>
            <div className="card">
              <img src={Greta} alt="" />
              <p style={{ width: "60%", textAlign: "center" }}>
                Greta Khachatryan Project manager
              </p>
            </div>
            <div className="card">
              <img src={Varditer} alt="" />
              <p style={{ width: "75%", textAlign: "center" }}>
                Varditer Baghdasaryan Project coordinator
              </p>
            </div>
          </div>
        </div>
        <div className="graphimage">
          <img src={graph} className="graph" style={{ width: "60%" }}></img>
        </div>
      </div>
    </div>
  );
};

export default Main2;
