import React from "react";
import pic from "../images/Frame.png";

const Main = () => {
  return (
    <div className="main">
      <div className="management">
        <h1>Your Ultimate Guide to Knowledge Management</h1>
        <div className="lines">
          <div className="lineone"></div>
          <div className="linetwo"></div>
        </div>
        <span>
          Welcome to Zeemust - your guide to harnessing the power of knowledge
          within your organization. We are a specialized consulting firm
          dedicated to data-driven knowledge management and crafting Knowledge
          Management tech-enriched solutions for companies operating in diverse
          domains. The company started its journey in the Knowledge Management
          domain in 2021, providing tailored KM solutions and guidance to
          companies that seek to maximize their performance and productivity.
        </span>
      </div>
      <img src={pic} alt="" />
    </div>
  );
};

export default Main;
