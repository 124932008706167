import React from "react";
import dots from "../images/dots.svg";
import elips from "../../src/images/Ellipse 5.png";

const Main3 = () => {
  return (
    <div className="app">
      <h1 className="maintitle" id="ourservices">
        Our services
      </h1>
      <div className="lines">
        <div className="lineone"></div>
        <div className="linetwo"></div>
      </div>
      <div className="ourservices">
        <div>
          <ul className="circle-list">
            <li className="whyzeem" id="aaa">
              <img src={elips} />
              Enhance your organizational capabilities with our specialized
              training program about Knowledge Management.
            </li>
            <li className="whyzeem">
              <img src={elips} />
              Streamline your information flow with our expert knowledge mapping
              and taxonomy services, creating a seamlessly organized and
              intuitively navigable system for your content.
            </li>
            <li className="whyzeem">
              <img src={elips} />
              We will help you discover effective software solutions and tools
              for capturing and utilizing knowledge to elevate operational
              excellence.
            </li>
            <li className="whyzeem">
              <img src={elips} />
              We identify, analyze, and value your knowledge assets, shaping a
              customized Knowledge Management framework to optimize
              decision-making, foster innovation, and enhance operational
              efficiency in your organization.
            </li>
          </ul>
        </div>
        <div className="dots">
          <img src={dots} id="dots" className="dots"></img>
        </div>
      </div>
    </div>
  );
};
export default Main3;
